.v2021 {
  text-align: center;
}
.v2021-logo {
  height: 55vmin;
  pointer-events: none;
}
.v2021-header {
  background-color: #061f2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.v2021-link {
  color: #27a4e3;
  padding: 10px 0;
  text-decoration: none;
  border-bottom: 2px solid #27a4e3;
}
.check-2020 {
  position: fixed;
  left: 50px;
  bottom: 50px;
  padding: 10px 0;
  border-bottom: 2px solid white;
  border-radius: 4px;
  font-size: 20px;
  color: white;
  text-decoration: none;
}
.v2021-callback {
  position: fixed;
  right: 50px;
  top: 50px;
  padding: 10px 0;
  border-bottom: 2px solid white;
  border-radius: 4px;
  font-size: 20px;
  color: white;
  text-decoration: none;
}
.v2021-social {
  margin-top: 50px;
}
.v2021-social img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
}
