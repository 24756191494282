.v2021 {
  text-align: center;
  position: relative;
  background-color: #0a5f9c;
  z-index: 0;
}

.v2021_logo {
  height: 40vmin;
  pointer-events: none;
}

.v2021_header {
  position: relative;
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1;
}

.v2021_link {
  color: #27a4e3;
  padding: 10px 0;
  text-decoration: none;
  border-bottom: 2px solid #27a4e3;
}

.check_2020 {
  position: fixed;
  left: 50px;
  top: 50px;
  padding: 10px 0;
  border-bottom: 2px solid white;
  font-size: var(--fs-button);
  color: white;
  text-decoration: none;
}

.v2021_callback {
  position: fixed;
  right: 50px;
  top: 50px;
  padding: 10px 0;
  border-bottom: 2px solid white;
  font-size: var(--fs-button);
  color: white;
  text-decoration: none;
}

.know_more {
  font-size: var(--fs-base);
  margin-top: 100px;
  position: fixed;
  bottom: 50px;
  text-align: center;
}

.know_more a {
  text-decoration: none;
  color: coral;
  border-bottom: 2px solid coral;
}

@media (max-width: 575px) {
  .floating_link {
    position: relative;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
  }
}
.v2021_social {
  margin-top: 50px;
}

.v2021_social img {
  margin: 0 10px;
  width: 20px;
  height: 20px;
}
